<template>
  <div>
    <v-row class="match-height">
      <!-- Multiple Column -->
      <v-col cols="12">
        <v-card>
          <v-card-title>Personel Oluştur</v-card-title>
          <v-card-text>
            <v-tabs v-model='tab' active-class='active-tab'>
              <v-tab>Kişisel</v-tab>
              <v-tab>İletişim</v-tab>
              <v-tab>Detay</v-tab>
            </v-tabs>
            <v-tabs-items v-model='tab' class='pt-5'>
              <v-tab-item>
                <v-row>
                  <v-col
                    v-for='item in ["personel_no", "isim", "soyisim", "dogum_tarihi", "dogum_yeri", "meslek_id"]'
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form[item].data"
                      :label="form[item].text"
                      :type="form[item].type"
                      outlined
                      dense
                      :placeholder="form[item].text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col
                    v-for='item in ["telefon", "eposta", "ulke_id", "posta_kodu_id", "cadde"]'
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form[item].data"
                      :label="form[item].text"
                      :type="form[item].type"
                      outlined
                      dense
                      :placeholder="form[item].text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row>
                  <v-col
                    v-for='item in [
                      "uyruk_id",
                      "sigorta_sirketi_id",
                      "kimlik_no",
                      "sosyal_guvenlik_no",
                      "kimlik_seri_no",
                      "kimlik_gecerlilik_tarihi",
                      "pasaport_no",
                      "pasaport_gecerlilik_tarihi",
                      "oturum_izin_no",
                      "oturum_izin_tarihi",
                      "guvenlik_belgesi"
                      ]'
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="form[item].data"
                      :label="form[item].text"
                      :type="form[item].type"
                      outlined
                      dense
                      :placeholder="form[item].text"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-card-actions class='pl-2'>
            <v-col cols="12">
              <v-btn color="primary" @click='Olustur'>
                {{ $t('Personeller.kaydet') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mdiWindowClose } from '@mdi/js'

export default {
  name: 'PersonelOlustur',
  data() {
    return {
      tab: null,
      MeslekSearch: '',
      SigortaSirketiSearch: '',
      UyrukSearch: '',
      tabs: [this.$t('Personeller.kisisel'), this.$t('Personeller.adres'), this.$t('Personeller.resmi')],
      data: {
        uyruk: '',
      },
      icons: {
        mdiWindowClose,
      },
      form: {
        personel_no: { type: 'text', data: '', text: this.$t('Personeller.personel_no'), value: 'personel_no' },
        isim: { type: 'text', data: '', text: this.$t('Personeller.isim'), value: 'isim' },
        soyisim: { type: 'text', data: '', text: this.$t('Personeller.soyisim'), value: 'soyisim' },
        dogum_tarihi: { type: 'date', data: '', text: this.$t('Personeller.dogum_tarihi'), value: 'dogum_tarihi' },
        dogum_yeri: { type: 'text', data: '', text: this.$t('Personeller.dogum_yeri'), value: 'dogum_yeri' },
        telefon: { type: 'text', data: '', text: this.$t('Personeller.telefon'), value: 'telefon' },
        eposta: { type: 'text', data: '', text: this.$t('Personeller.eposta'), value: 'eposta' },
        meslek_id: { type: 'text', data: '', text: this.$t('Personeller.meslek_id'), value: 'meslek_id' },
        uyruk_id: { type: 'text', data: '', text: this.$t('Personeller.uyruk_id'), value: 'uyruk_id' },
        ise_giris_tarihi: { type: 'date', data: '', text: this.$t('Personeller.ise_giris_tarihi'), value: 'ise_giris_tarihi' },
        ulke_id: { type: 'text', data: '', text: this.$t('Personeller.ulke_id'), value: 'ulke_id' },
        posta_kodu_id: { type: 'text', data: '', text: this.$t('Personeller.posta_kodu_ve_sehir'), value: 'posta_kodu_id' },
        cadde: { type: 'text', data: '', text: this.$t('Personeller.cadde'), value: 'cadde' },
        sigorta_sirketi_id: { type: 'text', data: '', text: this.$t('Personeller.sigorta_sirketi_id'), value: 'sigorta_sirketi_id' },
        kimlik_no: { type: 'text', data: '', text: this.$t('Personeller.kimlik_no'), value: 'kimlik_no' },
        sosyal_guvenlik_no: { type: 'text', data: '', text: this.$t('Personeller.sosyal_guvenlik_no'), value: 'sosyal_guvenlik_no' },
        kimlik_seri_no: { type: 'text', data: '', text: this.$t('Personeller.kimlik_seri_no'), value: 'kimlik_seri_no' },
        kimlik_gecerlilik_tarihi: { type: 'date', data: '', text: this.$t('Personeller.kimlik_gecerlilik_tarihi'), value: 'kimlik_gecerlilik_tarihi' },
        pasaport_no: { type: 'text', data: '', text: this.$t('Personeller.pasaport_no'), value: 'pasaport_no' },
        pasaport_gecerlilik_tarihi: { type: 'date', data: '', text: this.$t('Personeller.pasaport_gecerlilik_tarihi'), value: 'pasaport_gecerlilik_tarihi' },
        oturum_izin_no: { type: 'text', data: '', text: this.$t('Personeller.oturum_izin_no'), value: 'oturum_izin_no' },
        oturum_izin_tarihi: { type: 'date', data: '', text: this.$t('Personeller.oturum_izin_tarihi'), value: 'oturum_izin_tarihi' },
        guvenlik_belgesi: { type: 'text', data: '', text: this.$t('Personeller.guvenlik_belgesi'), value: 'guvenlik_belgesi' },
      },
    }
  },
  mounted() {
    this.$store.state.createDialog = false
  },
  methods: {
    async Olustur() {
      const veri = {}
      await Object.keys(this.form).forEach((key)=> {
        const item = this.form[key]
        veri[item.value] = item.data
      })
      console.log(veri)
      this.$store.dispatch('Action', { name: 'Personeller/PersonellerOlustur', data: veri })
    },
    filter(item, queryText, itemText) {
      queryText = queryText.replace(/[İı]/g, 'i')
      itemText = itemText.replace(/[İı]/g, 'i')

      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
    formItem(value){
      return this.form.find(item => item.value === value)
    }
  },
  created() {
  /*  this.$store.dispatch('Action', { name: 'MeslekGruplari/MeslekGruplariListesi' })
    this.$store.dispatch('Action', { name: 'EvrakTipleri/EvrakTipleriListesi' })*/
  },

}
</script>

<style lang='sass' scoped>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical)
  box-shadow: none !important
</style>
